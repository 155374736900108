import { Injectable } from '@angular/core';
import { SelectItem } from '../_models/baseModel';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor() { }

  // Get status
  getStatus() : SelectItem[] {
    return [
      { code: 'A', name: 'ใช้งาน' },
      { code: 'I', name: 'ไม่ใช้งาน' }
    ];
  }

  // Get project interest
  getProjectInterest() : SelectItem[] {
    return [
      {
        code: '00001',
        name: 'Monsane Exclusive Villa Ratchapruek-Pinklao', 
      },
      {
        code: '00002',
        name: 'Monsane Exclusive Ratchapruek-Chaengwatthana', 
      },
      {
        code: '00003',
        name: 'Siamese Holm Phahol - Vibhavadi', 
      },
      {
        code: '00004',
        name: 'Simaese Blossom Phahol - Vibhavadi', 
      },
      {
        code: '00005',
        name: 'Siamese KIN Ramintra', 
      },
      {
        code: '00006',
        name: 'Landmark At MRTA Station', 
      },
      {
        code: '00007',
        name: 'Landmark At Grand Station', 
      },
      {
        code: '00008',
        name: 'Landmark At Kaset TSH Station', 
      },
      {
        code: '00009',
        name: 'Blossom condo @ Sathorn-Charoenrat', 
      },
      {
        code: '00010',
        name: 'Blossom condo @ Fashion Beyond', 
      },
      {
        code: '00011',
        name: 'Siamese Sukhumvit 48', 
      }
    ];
  }
}
