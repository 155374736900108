import { Injectable } from '@angular/core';
import { UserProfile } from '../_models/authModel';
import { JwtHelperService } from '@auth0/angular-jwt'; 
const USER_KEY = 'auth-user';
const TOKEN_KEY = 'access-token'

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  jwtHelper = new JwtHelperService();
  constructor() {}

  clean(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void { 
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }


  public saveUser(user: UserProfile): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): UserProfile {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {
      roleCode: '',
      roleName: '',
      staffCode: '',
      avatarUrl: '',
      email: '',
      fullName: '',
      id: '',
      phone: '',
    };
  }

  loggedIn() {
    const token = localStorage.getItem(TOKEN_KEY);
    return !this.jwtHelper.isTokenExpired(token || '');
  }
}
